'use client';

import { FormControl, styled, Typography } from '@mui/material';

export const Field = styled(FormControl)(() => ({
	width: '100%',
}));

export const FieldLabel = styled(Typography)(({ theme }) => ({
	...theme.typography.body1,
	marginBottom: '0.5rem',
}));

export const ErrorMessage = styled(Typography)(({ theme }) => ({
	...theme.typography.body1,
	color: theme.palette.error.main,
}));

export const LinkTypography = styled(Typography)(({ theme }) => ({
	...theme.typography.body1,
	textAlign: 'right',
	'& a': {
		color: theme.palette.secondary.main,
		textDecoration: 'none',
	},
}));
