'use client';

import { styled } from '@mui/material';
import { LoadingButton as MuiLoadingButton } from '@mui/lab';

export const SubmitButton = styled(MuiLoadingButton)(({ theme }) => ({
	...theme.typography.messageTitle,
	fontWeight: '700',
	color: theme.palette.primary.contrastText,

	borderRadius: theme.typography.pxToRem(14),
	background: theme.palette.primary.main,
	padding: `${theme.typography.pxToRem(17)} ${theme.typography.pxToRem(20)}`,
}));
