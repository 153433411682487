'use client';

import { useState } from 'react';
import Link from 'next/link';
import { Box } from '@mui/material';

import { useForm } from '@shared/hooks/use-form';
import { SubmitButton } from '@shared/components/loading-button';
import { PasswordField } from '@shared/components/password-field';
import { useSignIn } from '@entities/auth/hooks/use-sign-in';
import {
	ErrorMessage,
	Field,
	FieldLabel,
	LinkTypography,
} from '@entities/auth/components/login/styles';
import { PageTitle } from '@shared/components/page-title';
import { useAuth } from '@entities/auth/providers/auth/use-auth';
import { FieldInput } from '@shared/components/field-input';
import { REQUEST_RESET_PATH } from '@shared/constants';

import { defaultErrorMessage, loginFormSchema } from './constants';
import { type TSignInFormValues } from './types';

export const LoginForm: React.FC = () => {
	const [loading, setLoading] = useState(false);

	const [serverValidationError, setServerValidationError] = useState('');

	const { sendCredentials, error: signInError, reset } = useSignIn();

	const { openSignedInPage } = useAuth();

	const {
		errors: { fieldErrors },
		registerField,
		getSubmitHandler,
	} = useForm({
		schema: loginFormSchema,
		defaultValues: {
			email: '',
			password: '',
		},
		onChange() {
			if (signInError) {
				reset();
			}
		},
	});

	const handleValid = (values: TSignInFormValues) => {
		if (loading) {
			return;
		}

		setLoading(true);
		setServerValidationError('');

		sendCredentials(
			{
				login: values.email,
				password: values.password,
			},
			{
				onSuccess: () => {
					setLoading(false);
					openSignedInPage();
				},
				onError: (error) => {
					setLoading(false);
					setServerValidationError(error);
				},
			},
		);
	};

	const handleSubmit = getSubmitHandler(handleValid);

	const emailHasError = Boolean(fieldErrors.email?.[0]);

	const passwordHasError = Boolean(fieldErrors.password?.[0]);

	const needToShowError = emailHasError || passwordHasError || serverValidationError;

	const errorMessage = serverValidationError || defaultErrorMessage;

	const commonFiledProps = {
		fullWidth: true,
		autoComplete: 'on',
		inputProps: { maxLength: 255 },
	};

	return (
		<>
			<Box mb={5}>
				<PageTitle sx={{ textAlign: 'center', padding: 0 }}>Log in</PageTitle>
			</Box>

			<form onSubmit={handleSubmit} noValidate name="login" id="login-form">
				<Box mb={3}>
					<Field>
						<FieldLabel>E-mail</FieldLabel>

						<FieldInput
							variant="outlined"
							size="small"
							id="form-email"
							placeholder="example@gmail.com"
							error={emailHasError || Boolean(serverValidationError)}
							{...commonFiledProps}
							{...registerField('email', { refProp: 'inputRef' })}
						/>
					</Field>
				</Box>

				<Box mb={2}>
					<Field>
						<FieldLabel>Password</FieldLabel>

						<PasswordField
							variant="outlined"
							size="small"
							id="form-password"
							error={passwordHasError || Boolean(serverValidationError)}
							{...commonFiledProps}
							{...registerField('password', { refProp: 'inputRef' })}
						/>
					</Field>
				</Box>
				<Box mb={2}>
					<LinkTypography>
						<Link href={REQUEST_RESET_PATH}>Forgot your password?</Link>
					</LinkTypography>
				</Box>

				{needToShowError && (
					<Box>
						<ErrorMessage>{errorMessage}</ErrorMessage>
					</Box>
				)}

				<Box mt={5}>
					<SubmitButton type="submit" variant="contained" fullWidth>
						Log in
					</SubmitButton>
				</Box>
			</form>
		</>
	);
};
