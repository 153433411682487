import { makeApi } from '@zodios/core';

import { loginCredentialsSchema, loginResponseTokensSchema } from './schemas';

export const authApi = makeApi([
	{
		alias: 'login',
		method: 'post',
		path: '/cabinet/auth/',
		parameters: [
			{
				name: 'credentials',
				type: 'Body',
				schema: loginCredentialsSchema,
			},
		],
		response: loginResponseTokensSchema,
	},
]);
