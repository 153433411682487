'use client';

import { styled, TextField } from '@mui/material';

export const FieldInput = styled(TextField)(({ theme }) => ({
	'& .MuiInputBase-root': {
		borderRadius: theme.typography.pxToRem(12),
	},

	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: theme.palette.custom.component.border,
	},

	'& input': {
		...theme.typography.body1,
		color: theme.palette.primary.main,
	},

	'& input::placeholder': {
		color: theme.palette.custom.component.placeholder,
	},
}));
