import { useCallback } from 'react';
import { type AxiosError } from 'axios';

import { authHooks } from '@shared/api/auth';
import { type TLoginCredentials, type TLoginErrorResponse } from '@shared/api/auth/types';
import { useAuth } from '@entities/auth/providers/auth/use-auth';
import { sendCredentialsResponseMapper } from '@entities/auth/mappers';

type TSignInOptions = {
	onSuccess?(): void;
	onError?(error: string): void;
};

const defaultServerError = 'Unknown message';

export const useSignIn = () => {
	const { mutate, isLoading, isError, error, reset } = authHooks.useLogin();

	const { updateTokenInfo } = useAuth();

	const sendCredentials = useCallback(
		(credentials: TLoginCredentials, { onSuccess, onError }: TSignInOptions = {}) => {
			mutate(credentials, {
				onSuccess(tokenInfo) {
					const mappedTokenInfo = sendCredentialsResponseMapper(tokenInfo);
					updateTokenInfo(mappedTokenInfo);
					onSuccess?.();
				},
				onError(mutationError) {
					const axiosError = mutationError as AxiosError<TLoginErrorResponse>;
					const errorMessage = axiosError?.response?.data?.message || defaultServerError;
					onError?.(errorMessage);
				},
			});
		},
		[mutate, updateTokenInfo],
	);

	return {
		sendCredentials,
		isLoading,
		isError,
		error,
		reset,
	};
};
