import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { type TextFieldProps } from '@shared/components/text-field';
import { ShowPasswordButton } from '@shared/components/password-field/styles';
import { FieldInput } from '@shared/components/field-input';

export const PasswordField: React.FC<Omit<TextFieldProps, 'type'>> = ({
	InputProps: inputProps = {},
	...restProps
}) => {
	const [passwordVisible, setPasswordVisible] = useState(false);

	const handleShowPasswordClick = () => setPasswordVisible((visible) => !visible);

	const { endAdornment, ...inputPropsRest } = inputProps;

	const customEndAdornment = (
		<ShowPasswordButton aria-label="toggle password visibility" onClick={handleShowPasswordClick}>
			{passwordVisible ? <VisibilityOff /> : <Visibility />}
		</ShowPasswordButton>
	);

	return (
		<FieldInput
			{...restProps}
			type={passwordVisible ? 'text' : 'password'}
			InputProps={{
				...inputPropsRest,
				endAdornment: (
					<>
						{endAdornment}
						{customEndAdornment}
					</>
				),
			}}
		/>
	);
};
