import { useState, useRef, useEffect, useCallback } from 'react';
import { type z } from 'zod';

import useLatest from '@shared/hooks/use-latest';

import { useMount } from '../use-mount';

import {
	type TUseFormHandleSubmit,
	type TUseFormProps,
	type TUseFormReturn,
	type TUseFormField,
	type TUseFormAnyField,
	type TUseFormRegisterFieldOptions,
	type TUseFormRegisterFieldProps,
} from './types';

const defaultGetChangedValue = (event: React.ChangeEvent<HTMLInputElement>) => event?.target?.value;

const defaultErrors = {
	formErrors: [],
	fieldErrors: {},
};

export const useForm = <TValues extends object>({
	schema,
	defaultValues,
	values: externalValues = {},
	onChange,
}: TUseFormProps<TValues>): TUseFormReturn<TValues> => {
	const [isValid, setIsValid] = useState(false);

	const [errors, setErrors] = useState<z.typeToFlattenedError<TValues>>(defaultErrors);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [, setUpdatesCount] = useState(0);

	const onChangeRef = useLatest(onChange);

	const submittedRef = useRef(false);

	const fieldsRef = useRef(new Map<keyof TValues, TUseFormAnyField<TValues>>());

	const getFieldNames = () => Array.from(fieldsRef.current.keys());

	const update = useCallback(() => setUpdatesCount((count) => count + 1), []);

	const focusField = useCallback(
		(fieldName: keyof TValues) => fieldsRef.current.get(fieldName)?.instance?.focus(),
		[],
	);

	const focusInvalidField = useCallback(
		(validationErrors: z.typeToFlattenedError<TValues>) => {
			getFieldNames().find((fieldName) => {
				const fieldErrorsKey = fieldName as keyof z.typeToFlattenedError<TValues>['fieldErrors'];

				if (validationErrors.fieldErrors[fieldErrorsKey]) {
					focusField(fieldName);
					return true;
				}

				return false;
			});
		},
		[focusField],
	);

	const revalidate = useCallback(
		(withFocus = false) => {
			const rawValues: Partial<TValues> = {};

			getFieldNames().forEach((fieldName) => {
				rawValues[fieldName] = fieldsRef.current.get(fieldName)?.value;
			});

			const validationResult = schema.safeParse(rawValues);
			setIsValid(validationResult.success);

			// update errors only for submitted forms
			if (submittedRef.current) {
				setErrors(validationResult.success ? defaultErrors : validationResult.error.formErrors);
			}

			// set focus on first invalid field
			if (!validationResult.success && withFocus) {
				focusInvalidField(validationResult.error.formErrors);
			}

			return validationResult;
		},
		[focusInvalidField, schema],
	);

	const ensureField = <
		TFieldName extends keyof TValues,
		TGetChangedValue extends (...args: unknown[]) => TValues[TFieldName],
		TOnFieldChange extends (value: TValues[TFieldName]) => void,
	>(
		fieldName: TFieldName,
		getChangedValue: TGetChangedValue,
		onFieldChange?: TOnFieldChange,
	): TUseFormField<TValues, TFieldName, TGetChangedValue> => {
		const registeredField = fieldsRef.current.get(fieldName);

		// return existing field
		if (registeredField) {
			return registeredField as TUseFormField<TValues, TFieldName, TGetChangedValue>;
		}

		const defaultValue = defaultValues[fieldName];

		// create new one
		const field: TUseFormField<TValues, TFieldName, TGetChangedValue> = {
			name: fieldName,
			value: externalValues[fieldName] ?? defaultValue,
			touched: false,
			get dirty() {
				return field.value !== defaultValue;
			},
			instance: null,
			ref: (instance: HTMLElement | null) => {
				field.instance = instance;
			},
			onChange: (...args) => {
				field.value = getChangedValue(...args);
				field.touched = true;

				onFieldChange?.(field.value);

				onChangeRef.current?.();
				update();
				revalidate();
			},
		};

		fieldsRef.current.set(fieldName, field);

		return field;
	};

	const getSubmitHandler: TUseFormHandleSubmit<TValues> = (onValid, onInvalid) => async (event) => {
		event?.preventDefault();

		submittedRef.current = true;

		setIsSubmitted(true);

		const validationResult = revalidate(true);

		if (validationResult.success) {
			await onValid(validationResult.data);
		} else {
			await onInvalid?.(validationResult.error.formErrors);
		}
	};

	const registerField = <
		TFieldName extends keyof TValues,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		TGetChangedValue extends (...args: any[]) => TValues[TFieldName],
		TValueProp extends string,
		TRefProp extends string,
	>(
		fieldName: TFieldName,
		fieldOptions: TUseFormRegisterFieldOptions<
			TValues,
			TFieldName,
			TGetChangedValue,
			TValueProp,
			TRefProp
		> = {},
	) => {
		const {
			getChangedValue = defaultGetChangedValue as TGetChangedValue,
			valueProp = 'value' as TValueProp,
			refProp = 'ref' as TRefProp,
			onChange: onFieldChange,
		} = fieldOptions;

		const field = ensureField(fieldName, getChangedValue, onFieldChange);

		return {
			name: fieldName,
			onChange: field.onChange,
			[refProp]: field.ref,
			// use getter here to force value updates
			get [valueProp]() {
				return field.value;
			},
		} as TUseFormRegisterFieldProps<TValues, TFieldName, TGetChangedValue, TValueProp, TRefProp>;
	};

	useEffect(() => {
		let wasChanged = false;

		const fieldNames = Object.keys(externalValues) as Array<keyof TValues>;

		fieldNames.forEach((fieldName) => {
			const field = fieldsRef.current.get(fieldName);

			// update value from external values only if field is not touched and value really changed
			if (field && !field.touched && externalValues[fieldName] !== field.value) {
				field.value = externalValues[fieldName] as TValues[keyof TValues];
				wasChanged = true;
			}
		});

		if (wasChanged) {
			// force rerender when something changed
			update();

			// and also revalidate form
			revalidate();
		}
	}, [externalValues, update, revalidate]);

	// revalidate form on mount to update isValid state
	useMount(() => {
		revalidate();
	});

	const isDirty = getFieldNames().some((fieldName) => fieldsRef.current.get(fieldName)?.dirty);

	return {
		isDirty,
		isValid,
		isSubmitted,
		errors,
		getSubmitHandler,
		registerField,
	};
};
