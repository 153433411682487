'use client';

import { createContext } from 'react';

import { type TLoginTokenInfo } from '@shared/api/auth/types';
import { type TRouterTransitionOptions } from '@shared/types';

export type TAuthContext = {
	openSignedInPage(this: void): void;
	openLoggedOutPage(this: void, options?: TRouterTransitionOptions): void;
	updateTokenInfo(this: void, tokenInfo: TLoginTokenInfo): void;
	clearTokenInfo(this: void): void;
};

const missingAuthProvider = 'You forgot to wrap your app in <AuthProvider>';

const invariantAuthProvider = (): never => {
	throw new Error(missingAuthProvider);
};

export const AuthContext = createContext<TAuthContext>({
	openSignedInPage: invariantAuthProvider,
	openLoggedOutPage: invariantAuthProvider,
	updateTokenInfo: invariantAuthProvider,
	clearTokenInfo: invariantAuthProvider,
});
